import api from "../index";

import hubSpotService from "../prequal/hubSpotService";

const create = async (data) => {
  const response = await api.post("/intakefunnel", data);
  return response.data;
};

const update = async (data) => {
  const response = await api.put("/intakefunnel", data);
  
  if ( data.email !== undefined ) {
    hubSpotService.upsertHubSpot({formData: {
      ...data,
      Created: new Date().toISOString()
    }}).then((response) => {
      if ( response.status === 200 ) {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(["identify",{email: data.email}]);
        _hsq.push(['trackPageView']);
      }
    });
  }
  return response.data;
};

const intakeFunnelService = {
  create,
  update,
};

export default intakeFunnelService;
