import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ExtendedRadioCard } from "../atoms/ExtendedRadioCard";
import { createIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";

const options = [
  {
    id: 1,
    title: "Starting Out!",
    value: "Starting Out",
    description: "I’m researching what I can build and how much it will cost.",
  },
  {
    id: 2,
    title: "Ready to Begin",
    value: "Ready to Begin",
    description: "What are my options?",
  },
  {
    id: 3,
    title: "Project In-Progress",
    value: "Project in Progress",
    description: "I’ve already started design, permitting and / or construction.",
  },
  {
    id: 4,
    title: "Legalizing an Existing ADU",
    value: "Legalizing an Existing ADU",
    description: "I have an unpermitted ADU on my property.",
  },
  {
    id: 5,
    title: "Property Shopping",
    value: "Property Shopping",
    description: "I am looking at properties where I could potentially build an ADU.",
  },
  {
    id: 6,
    title: "Something Else",
    value: "Something Else",
    description: "I have a unique project that I'd like to discuss.",
  }
]

export function FunnelStep1({ setCurrentStep }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(false);

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
  };

  const onSubmit = (data) => {
    const request = {
      project_phase: selected.value,
    };

    dispatch(createIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if ( selected.value === "Something Else") {
          setCurrentStep("FunnelEndCard1");
        }
        else {
          setCurrentStep("FunnelStep2");
        }
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <div className="pt-2 sm:pt-6 sm:px-6">
            <div>
              <h2 className="text-center text-xl sm:text-2xl leading-normal md:text-4xl md:leading-normal font-bold tracking-normal text-neutral-900">
                To help us best serve your project goals, please complete the following five short questions:
              </h2>
            </div>
            <div>
              <h1 className="text-center text-l sm:text-xl leading-normal md:text-3xl md:leading-normal font-bold tracking-normal text-neutral-900">
              Where are you in your project&nbsp;planning?
              </h1>
            </div>
          </div>
        }
        mainComponent={
          <>
          <RadioGroup value={selected} onChange={onChange}>
            <RadioGroup.Label className="sr-only">
              Select Option
            </RadioGroup.Label>
            <div className="flex justify-center mt-4 px-5">
              <div className="grid grid-cols-1 md:grid-cols-5 gap-y-2 md:gap-y-6 gap-x-6">
                {options.slice(0,5).map((option) => (
                  <div key={option.id}>
                    <ExtendedRadioCard item={option} />
                  </div>
                ))}
                <div key="6" className="md:col-span-5">
                  <ExtendedRadioCard item={options[5]}
                  optionClasses="w-full bg-white rounded-2xl border cursor-pointer relative flex" />
                </div>
              </div>
            </div>
          </RadioGroup>
          </>
        }
        footerComponent={
          <>
            <div className="mt-4 md:mt-5 flex justify-center sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
