import React from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CheckboxCard = ({ item, checked, optionClasses, onChange }) => {
  const defaultOptionClasses = "w-full md:w-36 md:aspect-[2/3] relative cursor-pointer rounded-2xl " +
                             "border bg-white flex items-top shadow-sm focus:outline-none";
  
  return (
    <label className={classNames(
      checked ? "border-red-400 ring-2 ring-red-400" : "border-gray-300",
      optionClasses ? optionClasses : defaultOptionClasses,
    )}>
      <input
        type="checkbox"
        className="sr-only"
        checked={checked}
        onChange={() => onChange(item)}
      />
      <div className="flex flex-1 py-2 sm:py-4 px-2 sm:px-4">
        <span className="flex flex-row sm:flex-col justify-start md:mx-auto">
          {item.icon && (
            <div className="w-16 h-16 sm:w-20 sm:h-20 lg:w-28 lg:h-28 flex-shrink-0 mx-0 mb-4 sm:mx-auto flex items-left sm:items-center justify-left sm:justify-center">
              <img src={item.icon} alt={item.alt} className="w-20 h-20 lg:w-28 lg:h-28 object-contain"/>
            </div>
          )}
          <div className="flex flex-col ml-2 sm:ml-0 h-full">
            <div className="flex text-left md:text-center h-full items-center sm:items-start justify-left md:justify-center text-xl sm:text-base lg:text-xl font-medium text-gray-800 w-full">
              {Array.isArray(item.title) ? (
                item.title.map((part, index) => (
                  index === item.title.length - 1 ? part : <>{part}<wbr/></>
                ))
              ) : (
                item.title
              )}
            </div>
            {item.description && (
              <div className="mt-2 text-xs sm:text-base text-gray-500">
                {item.description}
              </div>
            )}
          </div>
        </span>
      </div>
    </label>
  );
};
