
  import React from "react";

  const FunnelEndCard = ({ children }) => {
    return (
      <>
        <div className="overflow-hidden bg-transparent py-8 sm:py-12">
          <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
            <div className="grid grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 md:grid-cols-2 lg:items-start">
              {/* Left Side */}
              { children }

              {/* Right Side */}
              <div className="hidden md:flex sm:px-6 lg:px-0">
                <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                  <img
                    src="https://cdn.prod.website-files.com/63b71a6727a161f30cbd6e3e/672012739d63d6be6cbe5804_reseda_exterior.jpeg"
                    alt="Product screenshot"
                    width={618}
                    height={768}
                    className="rounded-xl"
                  />
                  <img
                    src="https://cdn.prod.website-files.com/63b71a6727a161f30cbd6e3e/63b726ca81f91d81a0228d5e_Revival%20Logo%20-%20Dark.svg"
                    alt="Product screenshot"
                    width={400}
                    className="mt-2 mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  export default FunnelEndCard;