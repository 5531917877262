import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ExtendedRadioCard } from "../atoms/ExtendedRadioCard";
import { updateIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";
import detachedImage from "../../assets/funnel-detached.png";
import attachedImage from "../../assets/funnel-attached.png";
import multipleImage from "../../assets/funnel-multiple.png";
import conversionImage from "../../assets/funnel-conversion.png";

const options = [
  {
    id: 1,
    title: "Detached",
    value: "Detached",
    description: "Independent structure.",
    icon: detachedImage,
    alt: "ADU with no connection to the main house."
  },
  {
    id: 2,
    title: "Conversion",
    value: "Conversion",
    description: "Renovating an existing structure, like a garage.",
    icon: conversionImage,
    alt: "House with an attached garage."
  },
  {
    id: 3,
    title: "Attached",
    value: "Attached",
    description: "Expanding an existing home with an ADU.",
    icon: attachedImage,
    alt: "ADU attached to the main house."
  },
  {
    id: 4,
    title: "Multiple",
    value: "Multiple",
    description: "I want to build mulitple ADUs, either on a single or multiple properties.",
    icon: multipleImage,
    alt: "Multiple detached ADUs located behind a duplex."
  },
  {
    id: 5,
    title: "I'm not sure",
    value: "I'm not sure",
    description: "I haven't decided what type of ADU I'd like to build.",
  }
]

export function FunnelStep2({ setCurrentStep }) {
  const dispatch = useDispatch();

  const { recordId } = useSelector((state) => state.intakefunnel);
  const [selected, setSelected] = useState(false);

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
  };

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      adu_type: selected.value,
    };

    dispatch(updateIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        if ( selected.value === "I'm not sure") {
          setCurrentStep("FunnelIntake");
        } else {
          setCurrentStep("FunnelStep3");
        }
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <div className="pt-2 sm:pt-6 sm:px-6">
            <div>
              <h1 className="text-center text-xl sm:text-2xl leading-normal md:text-4xl md:leading-normal font-bold tracking-normal text-neutral-900">
              What type of ADU do you want to build?
              </h1>
            </div>
          </div>
        }
        mainComponent={
          <>
          <RadioGroup value={selected} onChange={onChange}>
            <RadioGroup.Label className="sr-only">
              Select Option
            </RadioGroup.Label>
            <div className="flex justify-center mt-4 px-5">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-y-2 md:gap-y-6 gap-x-6">
                {options.slice(0,4).map((option) => (
                  <div key={option.id}>
                    <ExtendedRadioCard item={option}
                      optionClasses={"w-full md:w-48 min-[825px]:w-56 md:aspect-[2/3] relative cursor-pointer rounded-2xl border bg-white flex items-top shadow-sm focus:outline-none"}
                    />
                  </div>
                ))}
                <div key="5" className="md:col-span-4">
                  <ExtendedRadioCard item={options[4]}
                  optionClasses="w-full bg-white rounded-2xl border flex relative" />
                </div>
              </div>
            </div>
          </RadioGroup>
          </>
        }
        footerComponent={
          <>
            <div className="mt-4 md:mt-5 flex justify-center sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
