import React from "react";
import { RadioGroup } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const ExtendedRadioCard = ({ key, item, checked, active, optionClasses }) => {

  const defaultOptionClasses = "w-full md:w-36 min-[825px]:w-40 md:aspect-[2/3] relative cursor-pointer rounded-2xl " +
                               "border bg-white flex items-top shadow-sm focus:outline-none";
  return (
    <>
      <RadioGroup.Option
        value={item}
        className={({ checked, active }) =>
          classNames(
            checked ? "border-transparent" : "border-gray-300",
            active ? "border-red-400 ring-2 ring-red-400" : "",
            optionClasses ? optionClasses : defaultOptionClasses,
          )
        }
      >
        {({ checked, active }) => (
          <>
            <div className="flex flex-1 py-2 sm:py-4 px-2 sm:px-4">
              <span className="flex flex-row sm:flex-col justify-start md:mx-auto">
                { item.icon !== undefined &&
                  <RadioGroup.Label
                    as="div"
                    className="w-[125px] h-[100px] sm:w-[192px] sm:h-[154px] border-2 rounded-xl flex-shrink-0 mx-0 sm:mx-auto flex items-left sm:items-center justify-left sm:justify-center overflow-hidden"
                  >
                    <img src={item.icon} alt={item.alt} className="w-[125px] h-[100px] sm:w-[192px] sm:h-[154px]" />
                  </RadioGroup.Label>
                }
                <div className="flex flex-col ml-2 sm:ml-0">
                  <RadioGroup.Description
                    as="div"
                    className="flex text-left md:text-center justify-left md:justify-center text-base md:text-xl font-medium text-gray-800 w-full"
                  >
                  {item.title}
                  </RadioGroup.Description>
                  { item.description && (
                    <div className="mt-2 text-xs sm:text-base text-gray-500">
                      {item.description}
                    </div>
                  )}
                </div>
              </span>
              <span
                className={classNames(
                  active ? "border" : "border-2",
                  checked ? "border-red-400" : "border-transparent",
                  "pointer-events-none absolute -inset-px rounded-2xl"
                )}
                aria-hidden="true"
              />
            </div>
          </>
        )}
      </RadioGroup.Option>
    </>
  );
};
