import { useState } from "react";
import PageTracker from "../components/templates/PageTracker.js";

import {
  ContainerLayout,
  Header,
  Footer,
  FunnelStep1,
  FunnelStep2,
  FunnelStep3,
  FunnelStep4,
  FunnelIntake,
  FunnelEndCard1,
  FunnelEndCard2,
  FunnelEndCard3,
  FunnelEndCard4,
  FunnelCalendly,
} from "../components";

const STEPS = {
  FunnelStep1: FunnelStep1,
  FunnelStep2: FunnelStep2,
  FunnelStep3: FunnelStep3,
  FunnelStep4: FunnelStep4,
  FunnelIntake: FunnelIntake,
  FunnelEndCard1: FunnelEndCard1,
  FunnelEndCard2: FunnelEndCard2,
  FunnelEndCard3: FunnelEndCard3,
  FunnelEndCard4: FunnelEndCard4,
  FunnelCalendly: FunnelCalendly,
}

function Funnel() {
  const [CurrentStep, setCurrentStep] = useState("FunnelStep1");
  const [userPath, setUserPath] = useState(["FunnelStep1"]);
  const CurrentComponent = STEPS[CurrentStep];

  const goToNextStep = (nextStep) => {
    setUserPath([...userPath, nextStep]);
    setCurrentStep(nextStep);
  };

  const goBack = () => {
    if (userPath.length > 1) {
      const newPath = [...userPath];
      newPath.pop();
      setUserPath(newPath);
      setCurrentStep(newPath[newPath.length - 1]);
    }
  };

  return (
    <PageTracker page={CurrentStep}>
      <Header/>
      <ContainerLayout>
        <CurrentComponent
          setCurrentStep={goToNextStep}
          goBack={goBack}
          userPath={userPath}
        />
      </ContainerLayout>
      <Footer showBack={userPath.length > 1} handleBack={goBack}/>
    </PageTracker>
    );
}export default Funnel;
