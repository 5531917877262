import api from "..";

import hubSpotService from "../prequal/hubSpotService";

const create = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.post("/priceyouradu", request);

  if ( request.email !== undefined ) {  
    hubSpotService.upsertHubSpot({formData: {
      ...request,
      Created: new Date().toISOString()
    }}).then((response) => {
      if ( response.statusCode === 200 ) {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(["identify",{email: request.email}]);
        _hsq.push(['trackPageView']);
      }
    });
  }

  return response.data;
};

const update = async (request) => {
  // Make Request, Include Response in Variable
  const response = await api.put("/priceyouradu", request);

  if ( request.email !== undefined && request.turnstile_result === "success" ) {
    hubSpotService.upsertHubSpot({formData: {
      ...request,
      Created: new Date().toISOString()
    }}).then((response) => {
      if ( response.status === 200 ) {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['identify',{email: request.email}]);
        _hsq.push(['trackPageView']);
      }
    });
  }

  return response.data;
};

const priceYourADUService = {
  create,
  update,
};

export default priceYourADUService;
