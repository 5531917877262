import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // RouterProvider,
  // createBrowserRouter,
  // createRoutesFromElements,
} from "react-router-dom";
import { RootLayout } from "./components/templates/RootLayout";
import Intake from "./pages/Intake";
import PreQual from "./pages/PreQual";
import PriceYourADU from "./pages/PriceYourADU";
import Funnel from "./pages/Funnel";
import useLoadScript from "./hooks/useLoadScript";
import ReactGA from 'react-ga4';

function App() {
  const config = window.config || {};
  useLoadScript('//js.hs-scripts.com/' + config.REACT_APP_HUBSPOT_APP_ID + '.js', 'hubspot-script');
  useLoadScript("https://www.googletagmanager.com/gtag/js?id=G-7443514S7S"); // Google tag (gtag.js)
  
  <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer></script>


  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    function gtag(){
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag('js', new Date());
    gtag('config', 'G-7443514S7S');

    ReactGA.initialize('G-7443514S7S');
    ReactGA.send({ hitType: 'pageview', page: '/price-your-adu', title: 'Price Your ADU' });
  })

  return (
    <Router>
      <Routes>
        <Route element={<RootLayout />}>
          <Route path="/" element={<Intake />} />
        </Route>
        <Route element={<RootLayout />}>
          <Route path="/pq" element={<PreQual />} />
        </Route>
        <Route element={<RootLayout />}>
          <Route path="/price-your-adu" element={<PriceYourADU/>} />
        </Route>
        <Route element={<RootLayout />}>
          <Route path="/funnel" element={<Funnel/>} />
        </Route>
        <Route element={<RootLayout />}>
          <Route path="/welcome" element={<Funnel/>} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
