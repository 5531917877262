import React from "react";
import FunnelEndCard from "../templates/FunnelEndCard";
import { Button } from "../atoms/Button";

export const FunnelEndCard2 = ({ setCurrentStep }) => {
  return (
    <FunnelEndCard>
      {/* Left Side */}
      <div>
        <div className="px-6 md:px-0 lg:pl-4">
          <div className="mb-8">
            <div className="text-2xl font-bold leading-8 text-gray-600 mb-4 mr-1 text-center">
              <p className="">Thank you for your response!</p>
              <p className="mt-2">One of our knowledgable ADU experts will be glad to talk through options for your project.</p>
              <p className="mt-2">We love to help our clients plan the the projects that best fit their needs.</p>
              <p className="mt-2">You can schedule a free consultation call with one of them below.</p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <Button
            type={"submit"}
            variant={"primary"}
            size={"large"}
            onClick={() => setCurrentStep("FunnelCalendly")}
          >
            Schedule Consultation
          </Button>
        </div>
      </div>
    </FunnelEndCard>
  );
};
