import api from "../index";

const enroll = async (data) => {
  const response = await api.post("/enroll", data);
  return response.data;
};

const enrollmentService = {
  enroll,
};

export default enrollmentService;
