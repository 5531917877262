import { InlineWidget } from "react-calendly";
import { useSelector } from "react-redux";

export function FunnelCalendly({ setCurrentStep }) {

  const { formData } = useSelector((state) => state.intakefunnel);

  return (
    <>
      <div className="mb-4 min-[665px]:mb-0 mt-2 sm:mt-6 px-12 text-xl sm:text-2xl md:text-3xl font-bold flex flex-col items-center">
        Thank you for your interest in working with us! Please schedule your phone consultation with our ADU experts below.
      </div>
      <InlineWidget
        url="https://calendly.com/d/cqs6-7hh-xms/free-phone-consultation-with-revival-homes-adu?hide_gdpr_banner=1"
        prefill={{
          email: formData.email,
          firstName: formData.first_name,
          lastName: formData.last_name,
          name: formData.first_name + " " + formData.last_name,
          customAnswers: {
            a1: formData.phone_number.startsWith("1") ? formData.phone_number : "1" + formData.phone_number,
          }
        }}
        pageSettings={{
          hideEventTypeDetails: true,
        }}
      />
    </>
  );
}
