import React from "react";
import FunnelEndCard from "../templates/FunnelEndCard";
import { useForm } from "react-hook-form";
import { Button } from "../atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";

export const FunnelEndCard1 = ({ setCurrentStep }) => {
  const dispatch = useDispatch();

  const { recordId } = useSelector((state) => state.intakefunnel);

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      something_else: data.something_else,
    };

    dispatch(updateIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentStep("FunnelIntake");
      }
    });
  };

  const {
    register,
    handleSubmit,
  } = useForm({});

  return (
    <FunnelEndCard>
      {/* Left Side */}
      <div>
        <div className="px-6 md:px-0 lg:pl-4">
          <div className="mb-8">
            <div className="text-2xl font-bold leading-8 text-gray-600 mb-4 mr-1 text-center">
              <p className="">Thank you for your response!</p>
              <p className="mt-2">We’d love to learn more about the ADU project that you have in mind.</p>
              <p className="mt-2">Please describe your project in a few sentences below and our experts will review prior to your ADU consultation call.</p>
            </div>
          </div>
        </div>
        <div className="mx-8 md:mx-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <textarea
              rows={4}
              name="something_else"
              id="something_else"
              className="w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
              defaultValue={""}
              autoFocus="autofocus"
              {...register("something_else")}
            />
            <div className="mt-5 flex justify-center">
            <Button
              type={"submit"}
              variant={"primary"}
              size={"large"}
              onClick={handleSubmit(onSubmit)}
            >
              Continue
            </Button>
            </div>
          </form>
        </div>
      </div>
    </FunnelEndCard>
  );
};
