import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FunnelEndCard from "../templates/FunnelEndCard";
import { useForm } from "react-hook-form";
import { Button } from "../atoms/Button";
import { updateIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";

export const FunnelEndCard3 = ({ setCurrentStep }) => {
  const dispatch = useDispatch();
  const { recordId, formData } = useSelector((state) => state.intakefunnel);
  const [ locationSubmitted, setLocationSubmitted ] = useState(false);

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      email: formData.email,
      out_of_market_location: data.out_of_market_location,
    };

    dispatch(updateIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setLocationSubmitted(true);
      }
    });
  };

  const {
    register,
    handleSubmit,
  } = useForm({});

  return (
    <FunnelEndCard>
      {/* Left Side */}
      <div>
        <div className="px-6 md:px-0 lg:pl-4">
          <div className="mb-8">
            <div className="text-2xl font-bold leading-8 text-gray-600 mb-4 mr-1 text-center">
              <p className="">Thank you for your response!</p>
              <p className="mt-2">Revival Homes currently serves Greater Los Angeles, the Inland Empire, Orange County and San Diego.</p>
              <p className="mt-2">Please let us know where your ADU project is located, we are expanding throughout California in 2025.</p>
            </div>
          </div>
        </div>
        {
          locationSubmitted ?
            <div className="text-xl font-bold leading-8 text-gray-600 mb-4 mr-1 text-center">
              Thank you for your response, we'll be in touch soon.
            </div>
          :
            <div className="mx-8 md:mx-0">
              <form onSubmit={handleSubmit(onSubmit)}>
                <textarea
                  rows={4}
                  name="out_of_market_location"
                  id="out_of_market_location"
                  className="w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-400 sm:text-sm sm:leading-6"
                  defaultValue={""}
                  autoFocus="autofocus"
                  {...register("out_of_market_location")}
                />
                <div className="mt-5 flex justify-center">
                <Button
                  type={"submit"}
                  variant={"primary"}
                  size={"large"}
                  onClick={handleSubmit(onSubmit)}
                >
                  Continue
                </Button>
                </div>
              </form>
            </div>
          }
      </div>
    </FunnelEndCard>
  );
};
