import { useState } from "react";
import { useSelector } from 'react-redux';
import { RadioGroup } from "@headlessui/react";
import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ExtendedRadioCard } from "../atoms/ExtendedRadioCard";
import { updateIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";

const options = [
  {
    id: 1,
    title: "Los Angeles City / County",
    value: "Los Angeles City/County",
  },
  {
    id: 2,
    title: "Orange County",
    value: "Orange County",
  },
  {
    id: 3,
    title: "San Bernardino / Riverside Counties",
    value: "San Bernardino/Riverside",
  },
  {
    id: 4,
    title: "Ventura County",
    value: "Ventura County",
  },
  {
    id: 5,
    title: "San Diego City / County",
    value: "San Diego City/County",
  },
  {
    id: 6,
    title: "Other",
    value: "Other",
  }
]

export function FunnelStep4({ setCurrentStep }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(false);
  const { recordId } = useSelector((state) => state.intakefunnel);

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected(option);
  };

  const onSubmit = (data) => {
    const request = {
      id: recordId,
      project_location: selected.value,
      out_of_market: selected.value === "Other",
    };

    dispatch(updateIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentStep("FunnelIntake");
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <div className="pt-2 sm:pt-6 sm:px-6">
            <div>
              <h1 className="text-center text-xl sm:text-2xl leading-normal md:text-4xl md:leading-normal font-bold tracking-normal text-neutral-900">
                Where is your project property located?
              </h1>
            </div>
          </div>
        }
        mainComponent={
          <>
          <RadioGroup value={selected} onChange={onChange}>
            <RadioGroup.Label className="sr-only">
              Select Option
            </RadioGroup.Label>
            <div className="flex justify-center mt-4 px-5">
              <div className="grid grid-cols-1 md:grid-cols-5 gap-y-2 md:gap-y-6 gap-x-6">
                {options.slice(0,5).map((option) => (
                  <div key={option.id}>
                    <ExtendedRadioCard item={option} />
                  </div>
                ))}
                <div key="6" className="md:col-span-5">
                  <ExtendedRadioCard item={options[5]}
                  optionClasses="w-full bg-white rounded-2xl border cursor-pointer relative flex" />
                </div>
              </div>
            </div>
          </RadioGroup>
          </>
        }
        footerComponent={
          <>
            <div className="mt-4 md:mt-5 flex justify-center sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
