import React from "react";
import FunnelEndCard from "../templates/FunnelEndCard";
import { useSelector } from 'react-redux';
import { Button } from "../atoms/Button";

export const FunnelEndCard4 = ({ setCurrentStep }) => {
  const { formData } = useSelector((state) => state.intakefunnel);

  const endCardText = {
    "Starting Out": [
      "We love to work with clients to develop high quality and affordable ADU projects.",
      "Schedule a free call with one of our knowledgable ADU experts to learn more. "
    ],
    "Ready to Begin": [
      "We would love to help you develop and build your ADU project.",
      "Schedule a free call with one of our knowledgable ADU experts to discuss your ADU design, permitting and construction cost questions.",
    ],
    "Project in Progress": [
      "We would love to help you bring your ADU project to completion.",
      "Schedule a free call with one of our ADU experts to learn more about how we can help with in-progress projects."
    ],
    "Legalizing an Existing ADU": [
      
      "Revival Homes can help you permit your existing ADU quickly and efficiently.",
      "Schedule a free call with one of our ADU experts to learn about our process for ADU Legalization."
    ],
    "Property Shopping": [
      "Revival Homes can help you quickly evaluate the potential and estimated cost of building an ADU on a prospective property.",
      "Schedule a free call with one of our ADU experts to learn about our ADU Feasibility & Planning services."
    ]
  }

  return (
    <FunnelEndCard>
      {/* Left Side */}
      <div>
        <div className="px-6 md:px-0 lg:pl-4">
          <div className="mb-8">
            <div className="text-2xl font-bold leading-8 text-gray-600 mb-4 mr-1 text-center">
              <p className="">Thank you for your response!</p>
              { endCardText[formData.project_phase].map((text) => (
                  <p className="mt-2">{text}</p>
                ))
              }
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <Button
            type={"submit"}
            variant={"primary"}
            size={"large"}
            onClick={() => setCurrentStep("FunnelCalendly")}
          >
            Schedule Consultation
          </Button>
        </div>
      </div>
    </FunnelEndCard>
  );
};
