import React, { useEffect } from 'react';
// The "Price My ADU Interaction" was originally created for use with
// our Facebook retargeting campaign. Since that campaign already retargets
// based on this interaction, I'm going to continue using it even for
// the new intake funnel, but not going to rename it here.
import { trackPriceMyADUInteraction} from '../../features/track/track.js';

const PageTracker = ({ children, page }) => {
  useEffect(() => {
    trackPriceMyADUInteraction();
  }, [page]);

  return <>{children}</>;
};

export default PageTracker;