import React, { useState } from "react";

import {
  ContainerLayout,
  Header,
  Footer,
  ProgressBar,
  PreQualStep1,
  PreQualStep2,
  PreQualStep3,
  PreQualStep4,
  PreQualStep5,
  PreQualStep6,
  PreQualStep7,
  PreQualResults,
} from "../components";

const PreQual = () => {
  const [page, setPage] = useState(0);

  const formSteps = [
    <PreQualStep1 page={page} setPage={setPage} />,
    <PreQualStep2 page={page} setPage={setPage} />,
    <PreQualStep3 page={page} setPage={setPage} />,
    <PreQualStep4 page={page} setPage={setPage} />,
    <PreQualStep5 page={page} setPage={setPage} />,
    <PreQualStep6 page={page} setPage={setPage} />,
    <PreQualStep7 page={page} setPage={setPage} />,
    <PreQualResults page={page} setPage={setPage} />,
  ];

  const goBack = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <Header steps={formSteps} currentStep={page + 1} />
      <ProgressBar steps={formSteps} currentStep={page} />
      <ContainerLayout>{formSteps[page]}</ContainerLayout>
      <Footer showBack={page > 0} handleBack={goBack} />
    </>
  );
};

export default PreQual;
