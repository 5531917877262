import { useState } from "react";
import { Button, FormLayout } from "../../components";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxCard } from "../atoms/CheckboxCard";
import { updateIntakeFunnel } from "../../features/intakefunnel/intakeFunnelSlice";
import wrenchhammerImage from "../../assets/funnel-wrenchhammer.png";
import financingImage from "../../assets/funnel-financing.png";
import permitImage from "../../assets/funnel-permit.png";
import questionmarkImage from "../../assets/funnel-questionmark.png";
import architectureImage from "../../assets/funnel-architecture.png";

const options = [
  {
    id: 1,
    title: "Construction & Project Pricing",
    value: "Construction & Project Pricing",
    icon: wrenchhammerImage,
    alt: "A wrench and a hammer."
  },
  {
    id: 2,
    title: ["Permit Application/","Management"],
    value: "Permit Application/Management",
    icon: permitImage,
    alt: "A stamped and approved plan set."
  },
  {
    id: 3,
    title: "Design & Architecture",
    value: "Design & Architecture",
    icon: architectureImage,
    alt: "A set of architectural drawings."
  },
  {
    id: 4,
    title: "Financing",
    value: "Financing",
    icon: financingImage,
    alt: "The edifice of a bank"
  },
  {
    id: 5,
    title: "Other",
    value: "Other",
    icon: questionmarkImage,
    alt: "A question mark."
  }
]

export function FunnelStep3({ setCurrentStep }) {
  const dispatch = useDispatch();

  const { recordId } = useSelector((state) => state.intakefunnel);
  const [selected, setSelected] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  const onChange = (option) => {
    setSelected({
      ...selected,
      [option.value]: !selected[option.value],
    })
  };

  const onSubmit = (data) => {
    const selectedPriorites = Object.entries(selected)
      .filter(([_, isSelected]) => isSelected)
      .map(([value]) => value);

    const request = {
      id: recordId,
      project_priorities: selectedPriorites,
    };

    dispatch(updateIntakeFunnel(request)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        setCurrentStep("FunnelStep4");
      }
    });
  };

  return (
    <>
      <FormLayout
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        headerComponent={
          <div className="pt-2 sm:pt-6 px-6">
            <div>
              <h1 className="text-center text-xl sm:text-2xl leading-normal md:text-4xl md:leading-normal font-bold tracking-normal text-neutral-900">
              What are your project priorities? (Select as many as you'd like)
              </h1>
            </div>
          </div>
        }
        mainComponent={
          <>
          <div className="flex justify-center mt-4 px-5">
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-y-2 md:gap-y-6 gap-x-6">
              {options.map((option) => (
                <div key={option.id}>
                  <CheckboxCard 
                    item={option}
                    checked={selected[option.value] === true}
                    onChange={onChange}
                    optionClasses={"w-full h-full sm:w-32 lg:w-48 md:aspect-[2/3] relative cursor-pointer rounded-2xl border bg-white flex items-top shadow-sm focus:outline-none"}
                  />
                </div>
              ))}
            </div>
          </div>
          </>
        }
        footerComponent={
          <>
            <div className="mt-4 md:mt-5 flex justify-center sm:flex-row">
              <Button
                type={"submit"}
                variant={"primary"}
                size={"large"}
                onClick={handleSubmit(onSubmit)}
                disabled={!selected}
              >
                Continue
              </Button>
            </div>
          </>
        }
      />
    </>
  );
}
