import { ChevronLeftIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import React from "react";

export const Footer = ({ showBack, handleBack }) => {

  const handlePrivacyClick = () => {
    window.open('https://www.revivaladu.com/legal/privacy-policy');
  };

  return (
    <>
      <footer className="">
        <div
          className="mx-auto flex max-w-8xl items-center justify-between p-6 lg:px-12"
          aria-label="Global"
        >
          <div className="flex grow flex-row items-center justify-between">
            { showBack &&
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-transparent py-2.5 px-3.5 text-lg font-semibold text-neutral-500   hover:text-neutral-800 focus:text-neutral-600"
                onClick={handleBack}
              >
                <ChevronLeftIcon
                  className="-ml-0.5 h-6 w-6"
                  aria-hidden="true"
                />
                Back
              </button>
            }
            <button
              type="button"
              className="ml-auto inline-flex items-center gap-x-2 rounded-md bg-transparent py-2.5 px-3.5 text-lg font-semibold text-neutral-500   hover:text-neutral-800 focus:text-neutral-600"
              onClick={handlePrivacyClick}
            >
              <LockClosedIcon
                className="-ml-0.5 h-6 w-6"
                aria-hidden="true"
              />
              Privacy Policy
            </button>
          </div>
        </div>
      </footer>
    </>
  );
};
