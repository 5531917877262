import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import intakeFunnelService from "./intakeFunnelService";

const initialState = {
  recordId: "",
  formData: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createIntakeFunnel = createAsyncThunk(
  "intakefunnel/create",
  async (request, thunkAPI) => {
    try {
      return await intakeFunnelService.create(request);
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateIntakeFunnel = createAsyncThunk(
  "intakefunnel/update",
  async (request, thunkAPI) => {
    try {
      return await intakeFunnelService.update(request);
    } catch (error) {
      const message =
        (error.response?.data?.message) || error.message || error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const intakeFunnelSlice = createSlice({
  name: "intakefunnel",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createIntakeFunnel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createIntakeFunnel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.recordId = action.payload.id;
        state.formData = action.payload;
      })
      .addCase(createIntakeFunnel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateIntakeFunnel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIntakeFunnel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formData = action.payload;
      })
      .addCase(updateIntakeFunnel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export default intakeFunnelSlice.reducer;
